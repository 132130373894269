import {ConstructorPage} from "./pages/constructor/constructor";
import {FlowList} from "./pages/constructor/FlowList";
import {NavLink, Route, Switch} from "react-router-dom";
import {RelatedLoader} from "./components/Loader";
import {SubscriberList} from "./pages/subscriber/SubscriberList";
import {TagList} from "./pages/tag/TagList";
import {SubscriberView} from "./pages/subscriber/SubscriberView";
import {BotList} from "./pages/bot/BotList";
import {MailingList} from "./pages/mailing/mailingList";
import {Login} from "./pages/auth/Login";
import {ReportPage} from "./pages/Report";
import {WeekendPage} from "./pages/WeekendPage";
import {ManagerPage} from "./pages/ManagerPage";

const router = [
    {
      path: "/flow",
      Component: FlowList,
      exact: true,
    },
    {
        path: "/mailing",
        Component: MailingList,
        exact: true,
    },
    {
      path: "/flow/:id",
      Component: ConstructorPage,
      exact: true,
    },
    {
        path: "/mailing/:id",
        Component: ConstructorPage,
        exact: true,
    },
    {
        path: "/subscriber/:id",
        Component: SubscriberView,
        exact: true,
    },
    {
        path: "/subscriber",
        Component: SubscriberList,
        exact: true,
    },
    {
        path: "/tag",
        Component: TagList,
        exact: true,
    },
    {
        path: "/bot",
        Component: BotList,
        exact: true,
    },
    {
        path: "/report",
        Component: ReportPage,
        exact: true,
    },
    {
        path: "/weekend",
        Component: WeekendPage,
        exact: true,
    },
    {
        path: "/manager",
        Component: ManagerPage,
        exact: true,
    },
]

const nav = [
    { link: 'flow', name: 'Воронки' },
    { link: 'subscriber', name: 'Підписники' },
    /*{ link: 'user', name: 'Користувачі' },*/
    { link: 'tag', name: 'Теги' },
    { link: 'bot', name: 'Боти' },
    /*{ link: 'mailing', name: 'Розсилки' },*/
    { link: 'manager', name: 'Менеджери' },
    { link: 'weekend', name: 'Вихідні дні' },
    { link: 'report', name: 'Аналітика' },
]

function App() {
    if (!localStorage.getItem('authorization')) {
        return <Login />
    }

  return (
      <main>
          <aside>
              {
                  nav.map(el => (
                      <li key={el.link}>
                          <NavLink to={`/${el.link}`}>{el.name}</NavLink>
                      </li>
                  ))
              }
              <li>
                  <a style={{ cursor: 'pointer' }} onClick={() => {
                      localStorage.removeItem('authorization')
                      window.location.reload()
                  }}>Вийти</a>
              </li>
          </aside>
          <article>
              <Switch>
                  {router.map(({ path, Component, exact }) => (
                      <Route
                          key={path}
                          exact={exact}
                          path={path}
                          render={(props) => <Component {...props} />}
                          component={Component}
                      />
                  ))}
              </Switch>
          </article>
      </main>
  );
}

export default App;
