import {useEffect, useState} from "react";
import {RelatedLoader} from "../components/Loader";
import {Select} from "../components/Select";
import moment from "moment";
import {customAxios} from "../axios";

const getAllDatesInMonth = (year, month) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) =>
        new Date(year, month, i + 1).toLocaleDateString('en-CA')
    );
};

const isWeekend = (weekends, managerId, date) => weekends.includes(`${managerId}_${date}`);

// WeekendPage Component
export const WeekendPage = () => {
    const now = new Date();
    const [loading, setLoading] = useState(false);
    // const [weekends, setWeekends] = useState(["1_2024-11-12", "3_2024-11-10"]);
    const [weekends, setWeekends] = useState([]);
    const [managers, setManagers] = useState([]);

    const [calendarState, setCalendarState] = useState({
        year: now.getFullYear(),
        month: now.getMonth(),
        dates: [],
    });

    const getManagers = async () => {
        const { status, data } = await customAxios.get(`/ajax/manager`)
        if (status === 200) {
            setManagers(data.items)
        }
    }

    const getWeekends = async () => {
        const { status, data } = await customAxios.get(`/ajax/weekend`)
        if (status === 200) {
            setWeekends((data.items || []).map(el => (`${el.manager_id}_${el.weekend_date}`)))
        }
    }

    useEffect(() => {
        getManagers().then()
        getWeekends().then()
    }, [])

    useEffect(() => {
        setCalendarState((prev) => ({
            ...prev,
            dates: getAllDatesInMonth(prev.year, prev.month),
        }));
    }, [calendarState.year, calendarState.month]);

    const handleYearChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            setCalendarState((prev) => ({ ...prev, year: value }));
        }
    };

    const handleMonthChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value < 12) {
            setCalendarState((prev) => ({ ...prev, month: value }));
        }
    };
    const changeWeekendDay = async (managerId, date) => {
        const parseDate = `${managerId}_${moment(date).format("YYYY-MM-DD")}`
        if (weekends.includes(parseDate)) {
            setLoading(true)
            const { status, data } = await customAxios.post(`/ajax/weekend/destroy`, {
                date: moment(date).format("YYYY-MM-DD"),
                manager_id: managerId
            })
            if (status === 204) {
                setWeekends(weekends.filter(el => el !== parseDate))
            }
            setLoading(false)
        } else {
            setLoading(true)
            const { status, data } = await customAxios.post(`/ajax/weekend/create`, {
                date: moment(date).format("YYYY-MM-DD"),
                manager_id: managerId
            })
            if (status === 201) {
                setWeekends([...weekends, parseDate])
            }
            setLoading(false)
        }
    }

    return (
        <div className="weekend-page">
            {loading && <RelatedLoader />}
            <div>
                <div style={{ display: "flex", alignItems: "center", width: "400px", marginLeft: '1%' }}>
                    <div style={{ marginRight: "10px" }}>
                        <Select
                            label={"Місяць"}
                            options={Array.from({ length: 12 }, (_, i) => ({
                                id: i,
                                name: new Date(0, i).toLocaleString('uk-UA', { month: 'long' }),
                            }))}
                            otherProps={{
                                value: calendarState.month,
                                onChange: handleMonthChange,
                            }}
                        />
                    </div>
                    <div>
                        <Select
                            label={"Рік"}
                            options={Array.from({ length: 10 }, (_, i) => ({
                                id: now.getFullYear() - 5 + i,
                                name: now.getFullYear() - 5 + i,
                            }))}
                            otherProps={{
                                value: calendarState.year,
                                onChange: handleYearChange,
                            }}
                        />
                    </div>
                </div>
            </div>
            <table style={{ width: "98%", marginLeft: '1%' }} className="custom-table">
                <thead>
                <tr>
                    <th />
                    {calendarState.dates.map((date) => (
                        <th
                            style={{
                                padding: "3px",
                                textAlign: "center",
                                verticalAlign: "middle",
                            }}
                            key={date}
                        >
                            {new Intl.DateTimeFormat("default", {
                                day: "2-digit",
                                month: "2-digit",
                            }).format(new Date(date))}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {managers.map((manager) => (
                    <tr key={manager.id}>
                        <td>{manager.name}</td>
                        {calendarState.dates.map((date) => (
                            <td
                                className={"weekend-page-td"}
                                onClick={() => changeWeekendDay(manager.id, date)}
                                style={{
                                    cursor: "pointer",
                                    padding: "0",
                                    background: isWeekend(weekends, manager.id, date)
                                        ? "green"
                                        : "yellow",
                                }}
                                key={date}
                            />
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};