import {useEffect, useState} from "react";
import {customAxios} from "../axios";
import {Modal} from "../components/Modal";
import {RelatedLoader} from "../components/Loader";
import {Input} from "../components/Input";

export const ManagerPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)

    const getItems = async () => {
        setLoading(true)
            const { status, data } = await customAxios.get(`/ajax/manager`)
            if (status === 200) {
                setItems(data.items)
                setModal(false)
            }
        setLoading(false)
    }

    useEffect(() => {
        getItems().then()
    },[])

    const deleteManager = async (id) => {
        if (!window.confirm("Ви впевнені?")) return

        setLoading(true)
        const { status, data } = await customAxios.delete(`/ajax/manager/${id}`)
        if (status === 204) {
            await getItems()
        }
        setLoading(false)
    }

    return (
        <div style={{ width: "100%", position: "relative" }}>
            {loading && <RelatedLoader />}

            <button onClick={() => setModal(true)} style={{ background: "#5d8c51", margin: "1%" }} className="btn">Додати менеджера</button>
            <table className={"custom-table"}>
                <tr>
                    <th>Ім'я</th>
                    <th>CRM ID</th>
                    <th width={40} />
                </tr>
                {
                    (items || []).map(el => (
                        <tr key={el.id}>
                            <td>{el.name}</td>
                            <td>{el.crm_id}</td>
                            <td className={'icon-red'}><a onClick={() => deleteManager(el.id)}>&#10005;</a></td>
                        </tr>
                    ))
                }
            </table>
            {
                modal && <CreateManagerModal
                    close={getItems}
                />
            }
        </div>
    )
}

const CreateManagerModal = ({ close }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        name: "",
        crm_id: ""
    })

    const createData = async () => {
        setLoading(true)
        const { status, data } = await customAxios.post(`/ajax/manager`, {
            name: values.name, crm_id: values.crm_id
        })
        if (status === 201) {
            close()
        }
        setLoading(false)
    }

    return (
        <div>
            <Modal loading={loading} title={"Створити менеджера"} close={close}>
                <div>
                    <Input
                        label={"Ім'я"}
                        otherProps={{
                            value: values.name,
                            onChange: e => setValues({ ...values, name: e.target.value })
                        }}
                    />
                    <Input
                        label={"CRM ID"}
                        otherProps={{
                            value: values.crm_id,
                            onChange: e => setValues({ ...values, crm_id: e.target.value })
                        }}
                    />
                    <button disabled={values.name.trim().length === 0 || values.crm_id.trim().length === 0} onClick={createData} className={"btn w100"}>Створити</button>
                </div>
            </Modal>
        </div>
    )
}