import axios, { AxiosInstance } from "axios";

//const baseUrl = process.env.REACT_APP_BASE_API

export const customAxios = axios.create({
  baseURL: `https://botapi.at.university/`,
  // baseURL: `http://localhost:4000/`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("authorization")}`,
  },
});

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("authorization");
        window.location.href = "/";
      } else if (
        error.response.status === 422 ||
        error.response.status === 400
      ) { if (error.response.data?.errors) {
          Object.keys(error.response.data.errors).map((key) => {
            alert(`${key}: ${error.response.data?.errors[key]}`);
          });
        } else if (error.response.data.message) {
        alert(error.response.data.message);
        } else {
        alert("Validation error");
        }
      } else if (error.response.status === 403) {
        alert(error?.response?.data?.message);
      } else if (error.response.status === 500) {
        if (error?.response?.data) {
          if (error.response.data.error) {
            if (
              error.response.data.error.errors &&
              error.response.data.error.errors[0]
            ) {
              alert(error.response.data.error.errors[0].message);
            }
          } else if (error.response.data.message) {
            alert(error.response.data.message);
          } else {
            alert("Something went wrong");
          }
        } else {
          alert("Something went wrong");
        }
      }
    }
    return error;
  }
);
